import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '3WflFt0AhP0k5agSAfqagd',
  name: 'home-duplicated',
  blocks: [
    {
      id: 'vHL9gA3Xzc1nX9SoZjDpr',
      type: 'Carousel',
      props: {
        technicalName: 'Carousel - PORTUGAL',
        slides: [
          {
            id: 'TGtBPHcOJvntQe50Sdvn8',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5mWvKkXTbCj4XGAbZK7wW5/a81b69486d1eb081b8e1426d6da38792/GENERIC_HPBANNERS_iPhone13_Desktop_PT.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5AfwdpK43NKmCFdhDxxy3K/ba90799f806db45c15fe28e52a05db8d/GENERIC_HPBANNERS_iPhone13_Mobile_PT.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'PT - iPhone 13 x Generic',
            },
            link: {
              href: 'https://www.backmarket.pt/pt-pt/l/iphone-13/14c5d644-a43a-42a8-b8f3-37d5af3936fc',
            },
          },
          {
            id: '12avYkE2Q7EYRw30uK2yyw',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/39mTH2AnGVowbQ7Zdlm0O4/58d16dfe3212c6d43389a23d09f6b9fd/Good_Deals_HP_Desktop_PT.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/7cfXbWCdCsvd8GYExFPP2q/be200c2f890352a71fb3d28c79956ff6/Copy_of_HP_Mobile-portuguese.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'PT - Good Deals ',
            },
            link: {
              href: 'https://www.backmarket.pt/pt-pt/e/good-deals',
            },
          },
          {
            id: '4kJL4HszO9ISETT0PpbGp4',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/MycAx8orSrsBlKFfGEXZs/cfb42c4b2221358a4da1e36152d4b4ca/SUPERIORITY_Desktop_PT.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/1XUhlGoMmtLFwUYCsd1X2k/bb5ffbaea2495d9156113afb34c30d60/SUPERIORITY_Mobile_PT.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'PT - Superiority',
            },
            link: {
              href: 'https://www.backmarket.pt/pt-pt/about-us',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '1qKkheM417Z1ljzJzT2rCk',
      type: 'Categories',
      props: {
        title: 'Pensa diferente, pensa recondicionado',
        subtitle: 'Como novos, mas a um preço melhor.',
        categories: [
          {
            id: '654nwKX7DUFuDx4RY6ptyf',
            title: 'Samsung Galaxy',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/7JvDrHVSynJFea6uP7oIdn/c7a3c3900cad883dbab73b0326d07346/Galaxy_S21.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Galaxy S21',
            },
            tagline: 'Telefones intergalácticos, a preços terrestres.',
            link: {
              href: 'https://www.backmarket.pt/pt-pt/l/smartphones-samsung/99760870-ed75-482f-a626-2b4f964c55ae',
            },
          },
          {
            id: '61qD8YgSyM3YqBvHUUmOPj',
            title: 'MacBook',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6MZVE5kh4OulMTCQlbTuT8/ea5ad9286dbafb1a5a54a3fe411328ff/MacBook_Pro_Touchbar.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'MacBook Pro',
            },
            tagline:
              'MacBook Air e MacBook Pro a preços tão baixos que fariam corar o Tim Cook.',
            link: {
              href: 'https://www.backmarket.pt/pt-pt/l/todos-os-macbook/297f69c7-b41c-40dd-aa9b-93ab067eb691',
            },
          },
          {
            id: '5U0Ky4c2TkzfXyAug4aN5j',
            title: 'iPad',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/7aufBVKyO3XfgrRHhVcZcW/353a8d135f51d2fbe7423576b8e1f0d4/ipad_11_pro.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: '',
            },
            tagline: 'Porque o telemóvel às vezes não chega.',
            link: {
              href: 'https://www.backmarket.pt/pt-pt/l/ipad/6053d9e8-2eaa-4971-9b6e-79b8a16e4dee',
            },
          },
          {
            id: '6gWGhxlkBxZ9TzCXa4jXZn',
            title: 'Portáteis',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4rah4soJKbLIZaX0YrEIfQ/c3994b66064ce72bf0c380705269b0f6/Asus_Zen_book_Pro.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Laptop windows',
            },
            tagline: 'Portáteis para trabalhar e jogar, por menos.',
            link: {
              href: 'https://www.backmarket.pt/pt-pt/l/computadores-portateis/630dab14-5051-49b9-bc7b-bb20876d4850',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '3FJuehAE7xQZfGiEmrZQGo',
      type: 'Categories',
      props: {
        title: 'Outras categorias',
        subtitle: 'Nada se perde, nada se cria, tudo se recondiciona.',
        categories: [
          {
            id: 'bhkU9C5oJxhgRAncEKYo4',
            title: 'Smartphones Android',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/c3phFqsPsCEl7dJiGDX5d/d1dbe98c1fece131d19310a8d1240853/S20STORE.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Samsung s20',
            },
            tagline: 'Não vivemos só de maçãs.',
            link: {
              href: 'https://www.backmarket.pt/pt-pt/l/smartphones-android/5a66c4f6-0b1e-4080-9728-63333498ebbb',
            },
          },
          {
            id: 'ecuJXLCklY90mmPMvIQ5N',
            title: 'Áudio',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/IeAj1UDxFwXBby17xbSSg/7ad082ec9d74e6e6447d9e5d04b99e63/Airpods_Pro_1.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'AirPods Pro - Just Earphones',
            },
            tagline: 'A preços que são música para os teus ouvidos.',
            link: {
              href: 'https://www.backmarket.pt/pt-pt/l/som-auscultadores-colunas/6a6b4d5a-c50d-4a61-9a7a-3c3b1ce00e50',
            },
          },
          {
            id: '1Qd17xq5tat9j5PCW7HbJj',
            title: 'Portáteis',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4rah4soJKbLIZaX0YrEIfQ/c3994b66064ce72bf0c380705269b0f6/Asus_Zen_book_Pro.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Laptop windows',
            },
            tagline: 'Para trabalho, cinema e jogos, por menos.',
            link: {
              href: '',
            },
          },
          {
            id: '3dKFcL6uZGUMCBXTi326P',
            title: 'Consolas de Jogos',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4RrlDaeko9fXSPWbRjV9yW/e738d14f44fa05968aa56a7218daf9c9/EARTHSHOP-PS42-CATEGORYBLOCK.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Consoles & Gaming',
            },
            tagline: 'Do Game Boy à Realidade Virtual.',
            link: {
              href: 'https://www.backmarket.pt/pt-pt/l/consolas-e-jogos/0a092eda-8a90-4b60-85bd-8a3123b2fd84',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '4GraAqlKgyVmNeSYZdh6Qw',
      type: 'PressCarousel',
      props: {
        technicalName: 'PT - press carousel',
        title: 'O que dizem sobre nós!',
        subtitle: 'Só coisas boas, é claro!',
        articles: [
          {
            id: '7arw6DxpVw2rwl5TXQJ5Fq',
            text: 'Investir em tecnologia recondicionada é investir no planeta',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/J0y3gIqM0oDmR2AVqxnw2/110f6feee201d8b503e2774dabbf52d9/O_Jornal_Econ__mico.png',
              width: 1556,
              height: 338,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/1W2wkeymG1vclRQazjBUhS/a30dd53e0c773d03e9d69477bf5bb9bc/O_Jornal_Econ__mico.png',
              widthMobile: 1556,
              heightMobile: 338,
              alt: 'jornaleconomico',
            },
            link: {
              href: 'https://jornaleconomico.pt/noticias/investir-em-tecnologia-recondicionada-e-investir-no-planeta-938194',
            },
            publicationDate: '08/2022',
          },
          {
            id: '2OU8zPabfoFUxEukYowcvb',
            text: 'Plataforma de recondicionados Back Market fecha ronda de investimento de 449 milhões',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4dVmdTgZFE6m2MNdM4c8gp/8502c413b1a4163f7ef5d93ea761f3f3/jornaldenegcios.png',
              width: 860,
              height: 210,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5uT9OhHAX60r9wihgrH1wr/4a6d6f3bf8dba8021f31d3bef0c24301/jornaldenegcios.png',
              widthMobile: 860,
              heightMobile: 210,
              alt: 'jornaldenegocios',
            },
            link: {
              href: 'https://www.jornaldenegocios.pt/empresas/tecnologias/detalhe/plataforma-de-recondicionados-back-market-fecha-ronda-de-investimento-de-449-milhoes',
            },
            publicationDate: '01/2022',
          },
          {
            id: '5HsDL8umwHSywKReK7keNX',
            text: 'Back Market chega a Portugal com gadgets recondicionados por menos 70%',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/63IlVxYcqYmde72l0I3EU9/3838633510e3b71a1bf26bb981f087b8/Dinheiro_vivo_logo.jpg',
              width: 834,
              height: 378,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/63IlVxYcqYmde72l0I3EU9/3838633510e3b71a1bf26bb981f087b8/Dinheiro_vivo_logo.jpg',
              widthMobile: 834,
              heightMobile: 378,
              alt: 'dinheirovivo logo',
            },
            link: {
              href: 'https://www.dinheirovivo.pt/empresas/back-market-chega-a-portugal-com-gadgets-recondicionados-por-menos-70--13867482.html',
            },
            publicationDate: '06/2021',
          },
          {
            id: '1v1AyfaBKil5TGS145a3N8',
            text: 'Unicórnio francês chega a Portugal e capta ronda de investimento de 276 milhões',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3Q3EoHvfAyCzGrKSWFd2aX/2004df77adbc6e0f8b37b3337bed1931/logo_eco-07.png',
              width: 1200,
              height: 628,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3Q3EoHvfAyCzGrKSWFd2aX/2004df77adbc6e0f8b37b3337bed1931/logo_eco-07.png',
              widthMobile: 1200,
              heightMobile: 628,
              alt: 'ecosapo logo',
            },
            link: {
              href: 'https://eco.sapo.pt/2021/05/18/unicornio-frances-chega-a-portugal-e-capta-ronda-de-investimento-de-276-milhoes/',
            },
            publicationDate: '05/2021',
          },
          {
            id: '2uDvaiSuCwrNN9vBEmYgJJ',
            text: 'Back Market chega a Portugal e anuncia investimento de 276 milhões de euros',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4XmHv9KjwrqDXDYKg20k4T/93b34e1d48ff0ef8d3335befdb9133b7/1609948527855.jfif',
              width: 200,
              height: 133,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4XmHv9KjwrqDXDYKg20k4T/93b34e1d48ff0ef8d3335befdb9133b7/1609948527855.jfif',
              widthMobile: 200,
              heightMobile: 133,
              alt: 'pmemagazine.sapo.pt logo',
            },
            link: {
              href: 'https://pmemagazine.sapo.pt/back-market-chega-portuga-anuncia-investimento-276-milhoes-euros/',
            },
            publicationDate: '05/2021',
          },
          {
            id: '6w7PyInClfQ4qjcRY83ykO',
            text: 'Back Market ganha 276 milhões e entra em Portugal',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6bIKHhMqjqA3CV8EY5aob5/2b4bd6abbc0df1428ea57a8d63cb610f/logo-mobile.png',
              width: 1512,
              height: 164,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6bIKHhMqjqA3CV8EY5aob5/2b4bd6abbc0df1428ea57a8d63cb610f/logo-mobile.png',
              widthMobile: 1512,
              heightMobile: 164,
              alt: 'link to leaders logo',
            },
            link: {
              href: 'https://linktoleaders.com/back-market-ganha-276-milhoes-e-entra-em-portugal/',
            },
            publicationDate: '05/2021',
          },
          {
            id: '4wasUgF8biJnv5bXvMtg6D',
            text: 'Plataforma online de produtos recondicionados criada em França chega a Portugal',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6NnItapIFeGKWiv6UI8MOj/4c1174549b264312d1e4c11afe11a94c/informatica-1.png',
              width: 299,
              height: 138,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6NnItapIFeGKWiv6UI8MOj/4c1174549b264312d1e4c11afe11a94c/informatica-1.png',
              widthMobile: 299,
              heightMobile: 138,
              alt: 'exame informatica logo',
            },
            link: {
              href: 'https://visao.sapo.pt/exameinformatica/noticias-ei/mercados/2021-05-19-plataforma-online-de-produtos-recondicionados-criada-em-franca-chega-a-portugal/?amp',
            },
            publicationDate: '05/2021',
          },
          {
            id: 'PslLW2kVXpQmOwUAZFKUd',
            text: 'Back Market chega a Portugal (e com ronda de investimento de 276 milhões)',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4O9pwDgX87s5dGiikzm0wY/c87e9db38d8efc81e1fd63c1fa0d0a7b/noticias-ao-minuto.png',
              width: 380,
              height: 136,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4O9pwDgX87s5dGiikzm0wY/c87e9db38d8efc81e1fd63c1fa0d0a7b/noticias-ao-minuto.png',
              widthMobile: 380,
              heightMobile: 136,
              alt: 'noticiasaominuto logo',
            },
            link: {
              href: 'https://www.noticiasaominuto.com/economia/1758379/back-market-chega-a-portugal-e-com-ronda-de-investimento-de-276-milhoes',
            },
            publicationDate: '05/2021',
          },
          {
            id: 'fxLpdiyrcdv6wc95ydk7c',
            text: 'Este regresso às aulas quer-se mais sustentável. É possível poupar e ajudar o ambiente?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3Q3EoHvfAyCzGrKSWFd2aX/2004df77adbc6e0f8b37b3337bed1931/logo_eco-07.png',
              width: 1200,
              height: 628,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3Q3EoHvfAyCzGrKSWFd2aX/2004df77adbc6e0f8b37b3337bed1931/logo_eco-07.png',
              widthMobile: 1200,
              heightMobile: 628,
              alt: 'ecosapo logo',
            },
            link: {
              href: 'https://eco.sapo.pt/2021/09/14/este-regresso-as-aulas-quer-se-mais-sustentavel-e-possivel-poupar-e-ajudar-o-ambiente/',
            },
            publicationDate: '09/2021',
          },
          {
            id: '6Z9WFhf2A8I5j8oufOEFRZ',
            text: 'Um regresso às aulas sustentável: como poupar e ajudar o ambiente',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3Odl3agMPIDkreEpne3lB7/13ef6be0f64c4c75e4ee7dbe528c2463/Logo_Mundopais-filhos.png',
              width: 199,
              height: 200,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3Odl3agMPIDkreEpne3lB7/13ef6be0f64c4c75e4ee7dbe528c2463/Logo_Mundopais-filhos.png',
              widthMobile: 199,
              heightMobile: 200,
              alt: 'mundo pais&filhos logo',
            },
            link: {
              href: 'https://www.mundopaisfilhos.pt/l/um-regresso-as-aulas-sustentavel-como-poupar-e-ajudar-o-ambiente/',
            },
            publicationDate: '09/2021',
          },
          {
            id: '6Hl2262SPpqElP44cVJDNQ',
            text: 'Plataforma de aparelhos eletrónicos recondicionados de olho nos alunos',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/63IlVxYcqYmde72l0I3EU9/3838633510e3b71a1bf26bb981f087b8/Dinheiro_vivo_logo.jpg',
              width: 834,
              height: 378,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/63IlVxYcqYmde72l0I3EU9/3838633510e3b71a1bf26bb981f087b8/Dinheiro_vivo_logo.jpg',
              widthMobile: 834,
              heightMobile: 378,
              alt: 'dinheirovivo logo',
            },
            link: {
              href: 'https://www.dinheirovivo.pt/empresas/tecnologia/plataforma-de-aparelhos-eletronicos-recondicionados-de-olho-nos-alunos-14159279.html',
            },
            publicationDate: '10/2021',
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '6or8wdsKIntOdW1h3X6CsP',
      type: 'Faq',
      props: {
        title: 'Que mais?',
        subtitle: 'As quatro perguntas que mais nos fazem',
        questions: [
          {
            id: '5EMSn3OmPw4TkgpXiSaw7U',
            title:
              'Como sei que o meu dispositivo não vai avariar dentro de 1 mês?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Desde um processo de triagem de pré-listagem para todos os vendedores até às "encomendas mistério" efetuadas pela equipa técnica do Back Market (sob nomes de código secretos, naturalmente) damos 110% de nós para garantir que a tecnologia que te oferecemos é a que prometemos. Além disso, cada dispositivo inclui 30 dias para mudar de ideias e uma garantia de 1 ano.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '6nguCZQexJytrdrvyTkizf',
            title: 'Qual é a diferença entre renovado e novo?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Por fora, um smartphone renovado parece (e funciona) como novo. No entanto, é o que está no interior que realmente conta. A tecnologia renovada (smartphones, tablets, portáteis e computadores) tem muito menos impacto ambiental do que a nova.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '42wYbhqzdPRQJdEWVgkCUG',
            title: 'Espera! Mas, afinal, que mais é que vocês vendem?',
            text: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        'Podes proporcionar um descanso ao planeta em mais do que uma maneira e, no que toca à tecnologia, somos muito mais do que um smartphone com uma cara laroca. Desde sistemas de gaming até modeladores ou mesmo monitores de bebé e máquinas de café (olá, novos pais), temos grande parte das coisas.',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: BLOCKS.PARAGRAPH,
                },
              ],
              nodeType: BLOCKS.DOCUMENT,
            },
          },
          {
            id: '1qX3KvRqTSoKywxhIPlwoA',
            title:
              'Será que o T-800, de O Exterminador Implacável 2 - O Dia do Julgamento (1991), era tecnicamente “recondicionado”?',
            text: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        'Certamente, precisaríamos colocar a Skynet no nosso processo de certificação antes de darmos uma resposta definitiva, mas temos um palpite de que sim, é um cenário possível para o famoso "I\'ll be back" (Estarei de volta).',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: BLOCKS.PARAGRAPH,
                },
              ],
              nodeType: BLOCKS.DOCUMENT,
            },
          },
        ],
        rendering: 'HTML_ONLY',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.pt/pt-pt/help',
          },
          label: 'Encontrar respostas',
        },
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: 'Home',
  meta: {
    title: 'o (super) mercado dos recondicionados',
    description:
      'Desfruta dos produtos recondicionados no Back Market, até 70% mais baratos do que os novos! Entrega gratuita - Até 3 anos de garantia legal',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/691a8c40eb69bd3421c35d85007fca79/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo: 'o (super) mercado dos recondicionados',
  titleBreadcrumb: 'Home',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home-duplicated',
}

export default HOME_FALLBACK_DATA
